import React from 'react'
import CommanBanner from '../elements/CommanBanner'
import { IMAGES } from '../constants/theme'
import ReCAPTCHA from 'react-google-recaptcha'


const cards = [
  { id: '01', icon: "flaticon-telephone", title: "Call Now", detail: "+91 9833344165,", detail2: "+91 8007783999" },
  { id: '02', icon: "flaticon-email", title: "Location", detail: "ameya@auxindesignstuido.com,", detail2: "ruda@auxindesignstudio.com" },
  { id: '03', icon: "flaticon-placeholder", title: "Email Now", detail: "J1/ C 106, 1st floor, Shram siddhi Vinayak society, ", detail2: "Wadala Truck Terminal, Wadala East" },
]

const ContectUs = () => {
  function onChange(value) {
    console.log("Captcha value:", value);
  }
  return (
    <>
      <div className="page-content bg-white">
        <CommanBanner mainTitle="Contact Us" parentTitle="Home" pageName="Contact Us" bgImage={IMAGES.bnr6} />
        <section className="content-inner">
          <div className="container">
            <div className="row">

              {
                cards.map((i) => (
                  <div key={i.id} className="col-lg-4 col-md-12 m-b30 aos-item">
                    <div className="icon-bx-wraper style-8 bg-white" data-name={i.id}>
                      <div className="icon-md m-r20">
                        <span className="icon-cell text-primary"><i className={i.icon}></i></span>
                      </div>
                      <div className="icon-content">
                        <h4 className="tilte m-b10">{i.title}</h4>
                        <p className="m-b0">{i.detail}<br />{i.detail2}</p>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </section>
        <section className="content-inner-1 pt-0">
          <div className="map-iframe">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10667.78151216368!2d72.87453483812406!3d19.036004736227405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cf4a3914dba3%3A0x2f0b65582f97d1e9!2sWadala%20Truck%20Terminal%2C%20Sion%2C%20Mumbai%2C%20Maharashtra%20400037!5e0!3m2!1sen!2sin!4v1706208397503!5m2!1sen!2sin" className="align-self-stretch radius-sm" style={{ border: 0, width: '100%', minHeight: '100%' }} allowFullScreen></iframe>
          </div>
          <div className="container">
            <div className="contact-area aos-item">
              <div className="section-head style-1 text-center">
                <h6 className="sub-title text-primary">Contact Us</h6>
                <h2 className="title">Get In Touch With Us</h2>
              </div>
              <form className="dz-form dzForm contact-bx" >
                <div className="dzFormMsg"></div>
                <div className="row sp10">
                  <div className="col-sm-6 m-b20">
                    <div className="input-group">
                      <input type="text" className="form-control" required name="dzFirstName" placeholder="First Name" />
                    </div>
                  </div>
                  <div className="col-sm-6 m-b20">
                    <div className="input-group">
                      <input type="text" className="form-control" required name="dzLastName" placeholder="Last Name" />
                    </div>
                  </div>
                  <div className="col-sm-6 m-b20">
                    <div className="input-group">
                      <input type="text" className="form-control" required name="dzEmail" placeholder="Email" />
                    </div>
                  </div>
                  <div className="col-sm-6 m-b20">
                    <div className="input-group">
                      <input type="text" className="form-control" required name="dzPhoneNumber" placeholder="Phone No." />
                    </div>
                  </div>
                  <div className="col-sm-12 m-b20">
                    <div className="input-group">
                      <input type="text" className="form-control" required name="dzOther" placeholder="Subject" />
                    </div>
                  </div>
                  <div className="col-sm-12 m-b20">
                    <div className="input-group">
                      <textarea name="dzMessage" rows="5" className="form-control" placeholder="Message"></textarea>
                    </div>
                  </div>
                
                  <div className="col-sm-12 text-center">
                    <button name="submit" className="btn btn-primary btn-rounded">SUBMIT <i className="m-l10 fas fa-caret-right"></i></button>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default ContectUs