import React from 'react';
import CommanBanner from '../elements/CommanBanner';
import { IMAGES } from '../constants/theme';
import Working from '../components/Working';
import Projects from '../components/Projects';
import Testimonial1 from '../components/Testimonial1';
import Testimonial2 from '../components/Testimonial2';
import Faq from '../components/Faq';
import {motion} from 'framer-motion'

const workingProcess = [
    { num: "01", heading: "Ruda Dalvi", img: IMAGES.teamPic2, subTitle: "Founder and Partner", description: "A young dynamic Interior Designer graduated with honours & accolades from the renowned Rachana school Of Interior Design, Mumbai. Has been crafting a mark in the industry for the past 8+ years. She has two National Awards for designing. She imbues her contemporary yet ingenious vision with honed expertise to deliver premium design solutions in residential & commercial sectors alike. The impetus behind her design process is to amalgate pragmatic function and neoteric sensibilities in every space that engage with. innovative & experimental, the firms portfolio is a curation of projects that best exemplify the notion of bespoke concepts each unique in their expression, yet uniformly vibrant and flamboyant. With a focus on the tactility of materials & subtle shifts of mood through strategic decor planning and further now they are embarking on the path to create a footprint in the hospitality sector." },
    { num: "02", heading: "Ameya A Gaandhe", img: IMAGES.teamPic1, subTitle: "Founder and Partner", description: "Ameya A Gaandhe is the Principle Designer & a fourth-generation Designer of Gaandhe & Associates. He completed his degree at FTII, Pune and masters from Coventry University, United Kingdom. He was a contender and nominee for the prestigious RED DOT AWARD for designing a Coffee Kiosk for BUGGATTI.His career reached an high with working for companies like Jaquar - Landrover & Mercedes Benz. His sensor-rich projects are imbued with a modern mixture of found objects, repurposed materials, and furniture designed and executed at the highest level of international craft. Holding an international degree from Coventry University, UK and expertise of working with international brand he brings a new and polished vision in all their projects. Tailored, modern and elegant, rough, sleek and bold the interiors are conceived and meticulously crafted. He brings a Unique design approach towards each project.From concept to post construction is his skill, Ameya and his team of interior designers work holistically to realise each clients unique vision and work towards delivering." },
]

const AboutUs = () => {
    return (
        <>
            <div className="page-content bg-white">
                <CommanBanner mainTitle="About Us" parentTitle="Home" pageName="About Us" bgImage={IMAGES.bannerbg3} />
                {/* <section className=" section-full content-inner about-bx2">
                    <div className="content-inner bg-secondary subscribe-area" style={{ backgroundImage: `url(images/background/bg2-1.png)`, backgroundPosition: 'center' }}>
                    <div className="container">
                        <div className="row subscribe-content">
                            <div className="col-lg-6 aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
                                <motion.div className="section-head style-1 mb-0"
                                    initial={{ opacity: 0, y: "200%" }}
                                    whileInView={{ opacity: 1, y: "0%" }}
                                    transition={{ duration: 0.8 }}
                                >
                                    <h6 className="sub-title text-primary">NEWSLETTER</h6>
                                    <h2 className="title text-white">Stay Updated With Us !</h2>
                                </motion.div>
                            </div>
                            <div className="col-lg-6 aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="400">
                                <form className="dzSubscribe dz-subscription mt-3" action="#" method="post">
                                    <div className="dzSubscribeMsg dz-subscription-msg"></div>
                                    <motion.div className="input-group"
                                        initial={{ opacity: 0, y: "200%" }}
                                        whileInView={{ opacity: 1, y: "0%" }}
                                        transition={{ duration: 0.8, delay: 0.4 }}
                                    >
                                        <input name="dzEmail" required="required" className="form-control" placeholder="Enter Your Email Address..." type="email" />                                         
                                        <button name="submit" type="submit" className="btn btn-primary btn-rounded">Subscribe Now <i className="m-l10 fas fa-plus scale08"></i></button>
                                    </motion.div>
                                </form>
                            </div>


                        </div>
                    </div>
                </div>
                </section> */}
                 <section className="py-5 bg-secondary">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12 aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
                                <motion.div className="section-head style-1 mb-3 mb-lg-0"
                                    initial={{ opacity: 0, y: "100%" }}
                                    whileInView={{ opacity: 1, y: "0%" }}
                                    transition={{ duration: 1 }}
                                >
                                    <h6 className="sub-title text-primary">ABOUT US</h6>
                                    <h5 className="title text-white">Auxin Design Studio is a registered Interior Architecture Design, Consultancy & Management Firm. The firm is lead by two Award wining Interior designers. ADS has completed 50 projects over the span of years. We pride ourselves on taking on a select number of projects with high quality of attention to detail.
The company was founded as a way of offering tailor made design services with the highest level of professionalism.
</h5>
<h5 className="title text-white">
Every ADS concept evolves from a close collaboration between our team and our clients. We listen, research & ultimately implement a design concept that is aesthetically compelling, budget & deadline compliant and uniquely custom made for and or an entity. ADS has completed over 2,00,000 sq ft of Residential, Commercial & Hospitality Design. Our current projects are spread across Mumbai, Surat & Silvassa.</h5>
                                </motion.div>
                            </div>
                         
                        </div>
                    </div>
                </section>
                <section className="section-full content-inner about-bx2" style={{ backgroundImage: `url(${IMAGES.background2})`, backgroundPosition: 'right bottom', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                    <div className="container">
                        <Working />
                    </div>
                </section>
                <section className="content-inner-2">
                    <div className="container">
                        <div className="section-head style-1 text-center">
                            <h6 className="sub-title text-primary">MEET THE FOUNDERS</h6>
                            <h2 className="title">Our Team</h2>
                        </div>
                        <div className="row">
                            {
                                workingProcess.map((i) => (
                                    <motion.div className="col-lg-6 col-sm-6" key={i.num}
                                        initial={{ opacity: 0, y: '100%' }}
                                        whileInView={{ opacity: 1, y: "0" }}
                                        transition={{ duration: 0.8}}
                                        style={{marginBottom: '30px',
                                        
                                    }}
                                    >
                                        <div className="work-process shadow text-center m-b30" style={{
                                            height: '100%',
                                        }}>
                                            <div style={{
                                                width: '200px',
                                                height: '200px',
                                                borderRadius: '50%',
                                                overflow: 'hidden',
                                                margin: '0 auto',
                                                marginBottom: '20px'
                                            }} className="img-circle
                                            ">
                                                <img src={i.img} alt="" />
                                            </div>
                                            <h4 className="title m-b15">{i.heading}</h4>
                                            <h6 className="sub-title" style={{
                                                color: '#db9b24d9',
                                                marginBottom: '20px'
                                            }}>{i.subTitle}</h6>
                                            <p className="m-b0">{i.description}</p>
                                        </div>
                                    </motion.div>
                                ))
                            }
                        </div>
                    </div>
                </section>
                <section className="content-inner-2">
                    <Projects />
                </section>
                <section className="content-inner-2" style={{ backgroundImage: `url(${IMAGES.bg2})`, backgroundPosition: 'right bottom', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                    <Testimonial1 />
                </section>
                <section className="content-inner-2">
                    <Testimonial2 />
                </section>
                <section className="section-full content-inner overflow-hidden" style={{ backgroundImage: `url(${IMAGES.bg1})`, backgroundPosition: 'left top', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                    <Faq />
                </section>
            </div>
            
        </>
    )
}
export default AboutUs;