import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import CountUp from 'react-countup'
import { motion } from 'framer-motion'



// import img
import pic1 from '../assets/images/about/pic1.png'
import pic2 from '../assets/images/about/pic2.png'
import pic3 from '../assets/images/about/pic3.png'


const accordian = [
    { icon: "flaticon-crane", key: 0, heading: "Curated", text: "Each design curated by Auxin Design Studio is a testament to the individuality of our clients, ensuring that their space becomes a living canvas that speaks volumes about their personality and aspirations. From luxurious estates to premium office spaces and distinctive residences, our portfolio is a testament to the diversity we've successfully undertaken over the years." },
    { icon: "flaticon-interior-design", key: 1, heading: "Synergy", text: "At the core of our philosophy is the synergy of architectural principles with the enchantment of design, creating an enduring impression of sincerity and commitment in our work. The magic unfolds when these principles synergize, leaving an everlasting mark on the spaces we transform. Our extensive expertise in the field serves as the driving force behind our ability to bring our clients' dreams to life." },
    { icon: "flaticon-blueprint", key: 2, heading: "Elegance", text: "With Auxin Design Studio, you're not just getting a design; you're getting an experience. An experience that reflects your essence, tells your story, and transforms your space into a haven of elegance and sophistication. Trust in our dedication to turning dreams into reality and let your space become a masterpiece curated by the experts at Auxin Design Studio." },
]


const Working = () => {
    return (
        <>
            <div className="row">
                <div className="col-lg-6">
                    <div className="dz-media">
                        <div className="img1 aos-item">
                            <img src={pic1} alt="" />
                        </div>
                        
                        <div className="img2 aos-item" data-aos="fade-up">
                            <img src={pic2} alt="" />
                        </div>
                        <div className="img3 aos-item">
                            <img src={pic3} alt="" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 align-self-center">
                    <div className="year-exp">
                        <motion.h2 className="year aos-item counter" data-aos="fade-up"
                            initial={{ opacity: 0, y: "100%" }}
                            whileInView={{ opacity: 1, y: "0%" }}
                            transition={{ duration: 0.5, delay: 0.3 }}
                        >
                            <CountUp
                                end={15}
                                duration={3}
                            />
                        </motion.h2>
                        <motion.h4 className="text aos-item" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600"
                            initial={{ opacity: 0, y: "100%" }}
                            whileInView={{ y: "0%", opacity: 1 }}
                            transition={{ duration: 1, delay: 0.8 }}
                        >
                            
                                        <span className="text">Years Of <br /> Experience</span>
                                    
</motion.h4>
                    </div>
                    <motion.h className="m-b30 aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="400"
                        initial={{ opacity: 0, y: "100%" }}
                        whileInView={{ opacity: 1, y: "0%" }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                    >Auxin Design Studio is renowned for its integrity and unique approach to design. Our hallmark lies in our attention to detail and the ability to resonate with the unique tastes of our clients. What sets us apart is our aptitude for crafting designs that not only beautify spaces but also narrate the personal story of its inhabitants.
</motion.h>
                    <div className="accordion dz-accordion about-faq" id="aboutFaq">
                        <Accordion defaultActiveKey="0">
                            {
                                accordian.map((i, ind) => (
                                    <Accordion.Item eventKey={`${i.key}`} key={ind}>
                                        <motion.div
                                            initial={{ opacity: 0, x: "100%" }}
                                            whileInView={{ opacity: 1, x: "0%" }}
                                            transition={{ duration: 1 }}
                                        >
                                            <Accordion.Header className="accordion-header" id="flush-headingOne">
                                                    <i className={i.icon}></i>
                                                    {i.heading}
                                                    <span className="toggle-close"></span>
                                        </Accordion.Header>
                                            <Accordion.Body>
                                                {i.text}
                                            </Accordion.Body>
                                        </motion.div>
                                    </Accordion.Item>
                                ))
                            }
                        </Accordion>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Working