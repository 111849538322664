import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

import 'react-modal-video/scss/modal-video.scss';
import "./assets/vendor/switcher/switcher.css";
import "./assets/vendor/swiper/swiper-bundle.min.css";
import './assets/css/style.css';

//layout
import Header from "./components/Header";
// import Header2 from "./components/Header2";
import Footer from "./components/Footer";
import Footer2 from "./components/Footer2";
import Footer3 from "./components/Footer3";
import ScrollToTop from "./components/ScrollToTop";
//Pages
import Home from "./pages/Home";
import Home2 from "./pages/Home2";
import Home3 from "./pages/Home3";
import AboutUs from "./pages/AboutUs";
import Team from "./pages/Team";
import ComingSoon from "./pages/ComingSoon";
import Construction from "./pages/Construction";
import Error404 from "./pages/Error404";
import Portfolio from "./pages/Portfolio";
import PortfolioDetail from "./pages/PortfolioDetail";
import Services from "./pages/Services";
import ServicesDetail from "./pages/ServicesDetail";
// import BlogGrid from "./pages/BlogGrid";
// import LargLeftSidebar from "./pages/LargLeftSidebar";
// import ListLeftSidebar from "./pages/ListLeftSidebar";
// import BlogDetail from "./pages/BlogDetail";
import ContectUs from "./pages/ContectUs";

function App() {
  return (
    <>
      <BrowserRouter>
        <div className="page-wraper">
          <Routes>
            <Route path="/under-construct" element={<Construction />} />
            <Route path="/coming-soon" element={<ComingSoon />} />

            <Route element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/team" element={<Team />} />
              <Route path="/error-404" element={<Error404 />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/portfolio-details" element={<PortfolioDetail />} />
              <Route path="/services" element={<Services />} />
              <Route path="/services-details" element={<ServicesDetail />} />
              {/* <Route path="/blog-grid" element={<BlogGrid />} /> */}
              {/* <Route path="/blog-large-left-sidebar" element={<LargLeftSidebar />} /> */}
              {/* <Route path="/blog-list-left-sidebar" element={<ListLeftSidebar />} /> */}
              {/* <Route path="/blog-details" element={<BlogDetail />} /> */}
              <Route path="/contact-us" element={<ContectUs />} />
            </Route>
            <Route element={<Layout2 />}>
              <Route path="/home-3" element={<Home3 />} />
            </Route>
            <Route element={<Layout3 />}>
              <Route path="/home-2" element={<Home2 />} />
            </Route>
          </Routes>                    
        </div>
        <ScrollToTop />
      </BrowserRouter>
    </>
  );
}

function Layout() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer2 />
    </>
  )
}
function Layout2() {
  return (
    <>
      {/* <Header2 /> */}
      <Outlet />
      <Footer3 />
    </>
  )
}
function Layout3() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  )
}
export default App;
