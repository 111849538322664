import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

const card = [
    { id: 1, delay: 0.2, icon: 'flaticon-blueprint-1', title: 'Iconic Architecture', description: "At Auxin Design Studio, we believe that design is the manifestation of a compelling story. Our team, led by experienced architects, begins each project by curating a narrative that spans both practical and aspirational preferences. From intricate sketches to conceptual study models and sophisticated renders, we aspire to deliver unique spaces with a signature style, blending modern and classic influences."},
    { id: 2, delay: 0.4, icon: 'flaticon-interior-design', title: 'Interior Design', description: "Discerning spaces deserve bespoke interiors. Our team combines visual expertise with access to internationally renowned materials, craftsmanship, and processes. We create striking and exceptional homes, emphasizing elegant and timeless interiors. Throughout the process, we include sketches and elaborate mood board presentations, ensuring that our clients are actively involved in the creation of their personalized spaces." },
    { id: 3, delay: 0.6, icon: 'flaticon-interior-design-1', title: 'Furniture', description: "Commissioning everything from concept to build, our design studio creates luxuriously unique furniture that complements the overall architecture and aesthetic palette of high-caliber spaces. Our keen eye for design ensures that each piece of furniture meets our standards of quality. From entrance foyers to lounges and bedrooms, our furniture follows a cohesive aesthetic that is both visually striking and proportionally focused." },
    { id: 4, delay: 0.8, icon: 'flaticon-furniture', title: 'Decor', description: "The finishing touches highlight the soul of a space. Our exclusive team of consultants specializes in commissioning and sourcing bespoke works, including crystal chandeliers, precious display items, antiques, and limited edition pieces. Elevate your space with carefully curated accents and accessories that reflect your unique style." },
    { id: 5, delay: 1, icon: 'flaticon-home', title: 'Lighting', description: "Lighting brings out the spark in any space and at Auxin Design Studio, we understand its importance. Our team applies a fusion of classically symmetric yet experimentally bold style to design exceptional customized chandeliers. These pieces, manufactured in high-caliber materials by world-renowned studios, seamlessly amplify design elements by integrating lighting design with architectural features and interior concepts." },
    { id: 6, delay: 1.2, icon: 'flaticon-support', title: 'Turnkey Execution', description: "For a comprehensive approach from ideation to completion, Auxin Design Studio offers turnkey services. As a one-stop studio, we seamlessly bridge the gap between conception, design, and final build. Our turnkey business model ensures that clients receive articulate and cohesive results, providing better value for time and exceptional attention to detail. Experience the seamless transformation of your space with Auxin Design Studio." }, 
    { id: 7, delay: 1.4, icon: 'flaticon-home', title: 'Site Supervision', description: "Ensure the smooth execution of your project with our site supervision services. Our experienced team will oversee the construction process, ensuring that it adheres to the design plans and specifications. We provide regular site visits, quality control checks, and coordination with contractors to ensure that your project is completed to the highest standards." },
    { id: 8, delay: 1.6, icon: 'flaticon-interior-design', title: 'Site Analysis', description: "Before starting any project, it's crucial to conduct a thorough site analysis. Our team will assess the site's characteristics, including its topography, climate, and surroundings. This analysis helps us understand the site's potential and constraints, allowing us to design spaces that are harmonious with their environment." },
    { id: 9, delay: 1.8, icon: 'flaticon-blueprint', title: 'Hospitality Design', description: "Create unforgettable experiences for your guests with our hospitality design services. Whether it's a hotel, restaurant, or resort, our team will design spaces that are aesthetically pleasing, functional, and aligned with your brand identity. From guest rooms to public areas, we'll create a welcoming and immersive environment that leaves a lasting impression." },
    { id: 10, delay: 2, icon: 'flaticon-blueprint-1', title: 'Schematic Layouts', description: "Visualize the layout of your space with our schematic layout services. We'll create detailed floor plans that showcase the arrangement of rooms, furniture, and other elements. These layouts serve as a blueprint for the design process, allowing you to visualize the flow and functionality of your space before construction begins." },
    { id: 11, delay: 2.2, icon: 'flaticon-execution', title: 'Target-Based Time Scheduling', description: "Stay on track with your project timeline using our target-based time scheduling services. We'll create a detailed schedule that outlines the milestones and deadlines for each phase of the project. By setting clear targets and monitoring progress, we ensure that your project stays on schedule and is completed within the specified timeframe." },
    { id: 12, delay: 2.4, icon: 'flaticon-furniture', title: 'Custom Furniture Design', description: "Elevate your space with custom-designed furniture that perfectly complements your interior design. Our team of skilled craftsmen will create unique and tailored furniture pieces that reflect your style and meet your specific requirements. From concept to fabrication, we'll ensure that every piece is crafted with precision and attention to detail." },
]

const PopularService = () => {
    const [addActive, setAddActive] = useState(1)
    return (
        <>
            <div className="container">
                <div className="section-head style-1 text-center">
                    <h6 className="sub-title text-primary">POPULAR SERVICES</h6>
                    <h2 className="title">Our Featured Services</h2>
                    Welcome to Auxin Design Studio, where we bring your vision to life through our diverse range of design services. Explore the distinctive offerings that set us apart in the world of architecture and design.
                </div>
                
                <div className="row">
                    {
                        card.map((item, ind) => (
                            <motion.div key={ind} className="col-lg-4 col-sm-6 aos-item" 
                                initial={{ opacity: 0, y: "100%" }}
                                whileInView={{ opacity: 1, y: "0%" }}
                                transition={{ duration: 0.8, delay:item.delay }}
                            >
                                <div className={`icon-bx-wraper style-3  m-b30 ${addActive == item.id ? 'active' : ''}`}
                                    onMouseEnter={() => setAddActive(item.id)}
                                >
                                    <div className="icon-xl m-b30">
                                        <Link className="icon-cell"><i className={item.icon}></i></Link>
                                    </div>
                                    <div className="icon-content">
                                        <h4 className="title m-b10"><Link>{item?.title}</Link></h4>
                                        <p className="m-b30">{item?.description}</p>
                                        <Link to={'/contact-us'} className="btn btn-primary btn-rounded btn-sm hover-icon">
                                            <span>Contact Us </span>
                                            <i className="fas fa-arrow-right"></i>
                                        </Link>
                                    </div>
                                </div>

                            </motion.div>
                        ))
                    }
                </div>
            </div>

        </>
    )
}

export default PopularService