export const Menu = [
    {
        title: 'Home',	        
        // dropmenu: 'sub-menu-down',	
        path:'1' , 
                to: '/',					
        
    },
    {   
        title: 'About Us',	
        to: '/about-us',
        path:'2'
    },
    {
        title: 'Portfolio',
        // dropmenu: 'sub-menu-down',   s
        path:'3' ,      
        to: '/portfolio',
        // content: [            
        //     {
        //         subtitle: 'Portfolio',
        //         to: '/portfolio',
        //     },
        //     {
        //         subtitle: 'Portfolio Details',
        //         to: '/portfolio-details',
        //     },
        // ],
    },
    
    {
        title: 'Services',
        // dropmenu: 'sub-menu-down',
        to: '/services',
        path:'4', 
        // content : [
        //     {
        //         subtitle: 'Services',
        //         to: '/services',
        //     },
        //     {
        //         subtitle: 'Services Details',
        //         to: '/services-details',
        //     },
            
        // ],
    },
    {
        title: 'Contact Us',	
        to: '/contact-us',
        path:'5' 
    },
]