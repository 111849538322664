import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import { IMAGES } from '../constants/theme'
import { motion } from 'framer-motion'


const accordian = [
    { id: 1, key: 0, title: "What services does Auxin Design Studio offer?", data: "Auxin Design Studio offers a wide range of design and architecture services. Our services include- Architectural Facade Design Interior & Architectural Contracting, Raw, Finishing Material Supply & Management, Turn-key Solutions, MEPF, BIM Modeling, Hospitality Design, Commercial Design, Site Analysis, Site Survey Conceptual Design & Mood Boards, Schematic Layouts, Conceptual 3D Render, Material Selection & Procurement, Custom Furniture Design, Good For Construction Drawings, Generating BOQ (Budget of Quantities), General Contractors Estimate, Quotation from External Agencies (Agencycost estimate), Target based time Scheduling, Site progress Assessment, Site Supervision, Bill Certification. We strive to bring creativity, functionality, and sustainability to every project we undertake." },
    { id: 2, key: 1, title: "How can I get started with Auxin Design Studio for my project?", data: " Getting started with Auxin Design Studio is easy! Simply reach out to us through our contact page or by giving us a call. Our team will schedule a consultation to discuss your project requirements, goals, and budget. From there, we'll work closely with you to develop a customised design plan tailored to your needs." },
    { id: 3, key: 2, title: "Can Auxin Design Studio help with project management?", data: "Yes, Auxin Design Studio offers project management services to ensure that your project is completed smoothly and efficiently. Our team will oversee every aspect of the project, from initial concept development to final execution, to ensure that it meets your expectations and is delivered on time and within budget." },
    { id: 4, key: 3, title: "What sets Auxin Design Studio apart from other design firms?", data: "At Auxin Design Studio, we combine creativity, innovation, and technical expertise to deliver exceptional design solutions tailored to our clients' needs. Our collaborative approach, attention to detail, and commitment to sustainability set us apart and ensure that every project we undertake exceeds expectations." },
    { id: 5, key: 4, title: "How long does the design process typically take with Auxin Design Studio?", data: " The duration of the design process depends on the scope and complexity of the project. During the initial consultation, our team will provide you with a timeline outlining the various stages of the design process, from concept development to finalisation. We strive to work efficiently without compromising on quality to ensure timely delivery of your project." }
]


const Faq = () => {
    return (
        <>
            <div className="container">
                <div className="row align-items-center">
                    <motion.div className="col-lg-6 m-b30 aos-item" data-aos="fade-right" data-aos-duration="800" data-aos-delay="300"
                        initial={{ opacity: 0, x: "-100%" }}
                        whileInView={{ opacity: 1, x: "0%" }}
                        transition={{ duration: 1 }}
                    >
                        <div className="twentytwenty-img-area">
                            <div className="twentytwenty-container">
                                <img src={IMAGES.pic2_2} alt="" />
                            </div>
                        </div>
                    </motion.div>
                    <div className="col-lg-6 aos-item" data-aos="fade-left" data-aos-duration="800" data-aos-delay="600">
                        <div className="section-head style-1">
                            <h6 className="sub-title text-primary">FAQ</h6>
                            <h2 className="title">Get Every Answer From Here</h2>
                        </div>
                        <Accordion defaultActiveKey="0" className="dz-accordion accordion-sm">
                            {
                                accordian.map((i, index) => (
                                    <motion.div
                                        key={index}
                                        initial={{ opacity: 0, x: "100%" }}
                                        whileInView={{ opacity: 1, x: "0%" }}
                                        transition={{ duration: 1 }}
                                    >
                                        <Accordion.Item eventKey={`${i.key}`}>
                                            <Accordion.Header className="accordion-header" id="headingOne">
                                                {i.title}
                                                <span className="toggle-close"></span>
                                            </Accordion.Header>
                                            <div id="collapseOne" className="accordion-collapse collapse show" >
                                                <Accordion.Body>
                                                    <p className="m-b0">{i.data}</p>
                                                </Accordion.Body>

                                            </div>
                                        </Accordion.Item>
                                    </motion.div>
                                ))
                            }
                        </Accordion>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Faq