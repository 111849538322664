export const IMAGES = {
    logo1: require('../assets/images/logo.png'),
    logo3: require('../assets/images/logo-white-3.png'),
    video: require('../assets/images/video/pic2-2.jpg'),
    background2: require('../assets/images/background/bg2.png'),
    background3: require('../assets/images/background/bg1.png'),
    bannerbg3: require('../assets/images/banner/bnr3.jpg'),
    bannerbg4: require('../assets/images/banner/bnr4.jpg'),
    pic1: require('../assets/images/blog/pic1.jpg'),
    pic2: require('../assets/images/blog/blog-grid/pic2.jpg'),
    pic3: require('../assets/images/blog/blog-grid/pic3.jpg'),
    pic4: require('../assets/images/work/work-1/pic-1.jpg'),
    pic5: require('../assets/images/work/work-1/pic-2.jpg'),
    pic6: require('../assets/images/work/work-1/pic-3.jpg'),
    bg2: require('../assets/images/background/bg2.png'),
    clientBg: require('../assets/images/client-bg.png'),
    pic7: require('../assets/images/testimonials/pic1.jpg'),
    pic8: require('../assets/images/testimonials/pic2.jpg'),
    pic9: require('../assets/images/testimonials/pic3.jpg'),
    pic10: require('../assets/images/testimonials/pic4.jpg'),
    pic11: require('../assets/images/testimonials/pic5.jpg'),
    pic12: require('../assets/images/testimonials/pic6.jpg'),
    pic13: require('../assets/images/team/pic1.jpg'),
    pic14: require('../assets/images/team/pic2.jpg'),
    pic15: require('../assets/images/team/pic3.jpg'),
    pic16: require('../assets/images/team/pic4.jpg'),
    pic17: require('../assets/images/team/pic5.jpg'),
    bg1: require('../assets/images/background/bg1.png'),
    pic2_1: require('../assets/images/pic2-1.jpg'),
    pic2_2: require('../assets/images/pic2-2.jpg'),
    bg2_1: require('../assets/images/background/bg2-1.png'),
    progress: require('../assets/images/video/pic2-1.jpg'),
    white2: require('../assets/images/logo-white-2.png'),
    footer1: require('../assets/images/blog/recent-blog/pic1.jpg'),
    footer2: require('../assets/images/blog/recent-blog/pic2.jpg'),
    team1: require('../assets/images/team/pic1.jpg'),
    team2: require('../assets/images/team/pic2.jpg'),
    team3: require('../assets/images/team/pic3.jpg'),
    team4: require('../assets/images/team/pic4.jpg'),
    team5: require('../assets/images/team/pic5.jpg'),
    team6: require('../assets/images/team/pic6.jpg'),
    modal: require('../assets/images/img1.jpg'),
    comingsoonIcon: require('../assets/images/logo-icon.png'),
    bg8: require('../assets/images/bg8.jpg'),
    bnr1: require('../assets/images/banner/bnr1.jpg'),
    galleryImg1: require('../assets/images/work/work-1/pic-3.jpg'),
    galleryImg2: require('../assets/images/work/work-1/pic-4.jpg'),
    galleryImg3: require('../assets/images/work/work-1/pic-6.jpg'),
    galleryImg4: require('../assets/images/work/work-1/pic-5.jpg'),
    galleryImg5: require('../assets/images/work/work-1/pic-2.jpg'),
    galleryImg6: require('../assets/images/work/work-1/pic-1.jpg'),
    bnr8: require('../assets/images/banner/bnr8.jpg'),
    bnr7: require('../assets/images/banner/bnr7.jpg'),
    workPic2: require('../assets/images/work/pic2.jpg'),
    workPic3: require('../assets/images/work/pic3.jpg'),

    // clients :
    bugattiLogo: require('../assets/images/clients/bugatti.png'),
    landRoverLogo : require('../assets/images/clients/land-rover.png'),
    jayaHeMuseaumLogo : require('../assets/images/clients/jaya-he-museaum.png'),
    vedantaLogo: require('../assets/images/clients/vedanta.png'),
    jaguarLogo : require('../assets/images/clients/jaguar.png'),
    exceedLogo : require('../assets/images/clients/exceed.png'),



    service1: require('../assets/images/services/pic1.jpg'),
    service2: require('../assets/images/services/pic2.jpg'),
    service3: require('../assets/images/services/pic3.jpg'),
    service4: require('../assets/images/services/pic4.jpg'),
    service5: require('../assets/images/services/pic5.jpg'),
    service6: require('../assets/images/services/pic6.jpg'),


    workPic1: require('../assets/images/work/work-1/pic-1.jpg'),
    workPic2: require('../assets/images/work/work-1/pic-2.jpg'),
    workPic3: require('../assets/images/work/work-1/pic-3.jpg'),
    workWorkpic1: require('../assets/images/work/pic1.jpg'),
    workWorkpic2: require('../assets/images/work/pic2.jpg'),
    bnr6: require('../assets/images/banner/bnr6.jpg'),
    brand1: require('../assets/images/logo/logo-gray1.png'),
    brand2: require('../assets/images/logo/logo-gray2.png'),
    brand3: require('../assets/images/logo/logo-gray3.png'),
    brand4: require('../assets/images/logo/logo-gray4.png'),
    brand5: require('../assets/images/logo/logo-gray5.png'),
    brand6: require('../assets/images/logo/logo-gray6.png'),
    bnr5: require('../assets/images/banner/bnr5.jpg'),
    ServicesDetailBg2: require('../assets/images/background/bg2.png'),
    ServicePic1: require('../assets/images/services/service-detail/pic1.jpg'),
    ServicePic2: require('../assets/images/services/service-detail/pic2.jpg'),
    blogGridPic1: require('../assets/images/blog/large/pic2.jpg'),
    blogGridPic2: require('../assets/images/blog/large/pic1.jpg'),
    blogGridPic3: require('../assets/images/blog/large/pic3.jpg'),
    bnr4: require('../assets/images/banner/bnr4.jpg'),
    post1: require('../assets/images/blog/recent-blog/pic1.jpg'),
    post2: require('../assets/images/blog/recent-blog/pic2.jpg'),
    post3: require('../assets/images/blog/recent-blog/pic3.jpg'),
    post4: require('../assets/images/blog/recent-blog/pic4.jpg'),
    bnr2: require('../assets/images/banner/bnr2.jpg'),
    testimonial: require('../assets/images/testimonials/pic4.jpg'),
    avatar: require('../assets/images/testimonials/pic1.jpg'),
    avatar1: require('../assets/images/testimonials/pic2.jpg'),
    avatar2: require('../assets/images/testimonials/pic3.jpg'),
    home2Bg1: require('../assets/images/background/bg1.png'),
    home2slider1: require('../assets/images/main-slider/side3.jpg'),
    home2slider2: require('../assets/images/main-slider/side4.jpg'),
    home2slider3: require('../assets/images/main-slider/pic3.jpg'),
    home2slider4: require('../assets/images/main-slider/pic4.jpg'),
    home2slider5: require('../assets/images/main-slider/pic5.jpg'),
    home2about1: require('../assets/images/about/pic4.jpg'),
    home2Bg2: require('../assets/images/background/bg2-1.png'),
    home2sliderImg1: require('../assets/images/work/work-1/pic-1.jpg'),
    home2sliderImg2: require('../assets/images/work/work-1/pic-2.jpg'),
    home2sliderImg3: require('../assets/images/work/work-1/pic-3.jpg'),
    aboutTestimonial: require('../assets/images/about/pic5.jpg'),
    home3slider1: require('../assets/images/main-slider/slider3/pic1.jpg'),
    home3slider2: require('../assets/images/main-slider/slider3/pic2.jpg'),
    home3slider3: require('../assets/images/main-slider/slider3/pic3.jpg'),
    home3aboutUs1: require('../assets/images/about/pic6.jpg'),
    home3aboutUs2: require('../assets/images/about/pic7.jpg'),
    home3aboutpic8: require('../assets/images/about/pic8.jpg'),
    home3aboutpic9: require('../assets/images/about/pic9.jpg'),
    home3aboutpic10: require('../assets/images/about/pic10.jpg'),
    videoPic3: require('../assets/images/video/pic3.jpg'),
    home3blogimg1: require('../assets/images/blog/blog-grid/pic4.jpg'),
    home3blogimg2: require('../assets/images/blog/latest-blog/pic1.png'),
    home3blogimg3: require('../assets/images/blog/blog-grid/pic5.jpg'),
    home3blogimg4: require('../assets/images/blog/latest-blog/pic2.png'),
    home3blogimg5: require('../assets/images/blog/blog-grid/pic6.jpg'),
    home3blogimg6: require('../assets/images/blog/latest-blog/pic1.png'),
    footerBgimg: require('../assets/images/footer-bg.jpg'),
    footerLogoImg: require('../assets/images/logo-white.png'),

      //Switcher Images
    smallbgimg1 : require('../assets/images/switcher/small/background/bg1.jpg'),
    smallbgimg2 : require('../assets/images/switcher/small/background/bg2.jpg'),
    smallbgimg3 : require('../assets/images/switcher/small/background/bg3.jpg'),
    smallbgimg4 : require('../assets/images/switcher/small/background/bg4.jpg'),
    smallbgimg5 : require('../assets/images/switcher/small/background/bg5.jpg'),
    smallbgimg6 : require('../assets/images/switcher/small/background/bg6.jpg'),
    smallbgimg7 : require('../assets/images/switcher/small/background/bg7.jpg'),
    smallbgimg8 : require('../assets/images/switcher/small/background/bg8.jpg'),

    smallpattern1 : require('../assets/images/switcher/small/pattern/pt1.jpg'),
    smallpattern2 : require('../assets/images/switcher/small/pattern/pt2.jpg'),
    smallpattern3 : require('../assets/images/switcher/small/pattern/pt3.jpg'),
    smallpattern4 : require('../assets/images/switcher/small/pattern/pt4.jpg'),
    smallpattern5 : require('../assets/images/switcher/small/pattern/pt5.jpg'),
    smallpattern6 : require('../assets/images/switcher/small/pattern/pt6.jpg'),
    smallpattern7 : require('../assets/images/switcher/small/pattern/pt7.jpg'),
    smallpattern8 : require('../assets/images/switcher/small/pattern/pt8.jpg'),

    largebgimg1 : require('../assets/images/switcher/large/background/bg1.jpg'),
    largebgimg2 : require('../assets/images/switcher/large/background/bg2.jpg'),
    largebgimg3 : require('../assets/images/switcher/large/background/bg3.jpg'),
    largebgimg4 : require('../assets/images/switcher/large/background/bg4.jpg'),
    largebgimg5 : require('../assets/images/switcher/large/background/bg5.jpg'),
    largebgimg6 : require('../assets/images/switcher/large/background/bg6.jpg'),
    largebgimg7 : require('../assets/images/switcher/large/background/bg7.jpg'),
    largebgimg8 : require('../assets/images/switcher/large/background/bg8.jpg'),

    largepattern1 : require('../assets/images/switcher/large/pattern/pt1.jpg'),
    largepattern2 : require('../assets/images/switcher/large/pattern/pt2.jpg'),
    largepattern3 : require('../assets/images/switcher/large/pattern/pt3.jpg'),
    largepattern4 : require('../assets/images/switcher/large/pattern/pt4.jpg'),
    largepattern5 : require('../assets/images/switcher/large/pattern/pt5.jpg'),
    largepattern6 : require('../assets/images/switcher/large/pattern/pt6.jpg'),
    largepattern7 : require('../assets/images/switcher/large/pattern/pt7.jpg'),
    largepattern8 : require('../assets/images/switcher/large/pattern/pt8.jpg'),

    homepage2: require('../assets/images/background/homepage2.jpg'),

    // projects
    projectpic1: require('../assets/images/projects/bugatti.jpg'),
    projectpic2: require('../assets/images/projects/jaya-he-museum.jpg'),
    projectpic3: require('../assets/images/projects/priyankaChopra.JPG'),
    projectpic4: require('../assets/images/projects/baliHouse.jpg'),
    projectpic5: require('../assets/images/projects/beachHouse.jpg'),
    projectpic6: require('../assets/images/projects/exceed.jpg'),
    projectpic7: require('../assets/images/projects/hritikHouse.jpg'),
    projectpic8: require('../assets/images/projects/icecreamProject.jpg'),
    projectpic9: require('../assets/images/projects/atomOffice.jpg'),
    projectpic10: require('../assets/images/projects/virarHouse.jpg'),
    projectpic11: require('../assets/images/projects/VikramGadh.jpg'),
    projectpic12: require('../assets/images/projects/wadalaHouse.jpg'),

    // Team 
    teamPic1: require('../assets/images/team/pic1.jpg'),
    teamPic2: require('../assets/images/team/pic2.jpg'),

}

const appTheme = {
  IMAGES
}

export default appTheme;